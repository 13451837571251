import * as React from "react"
import Layout from "../../components/Layout"
import LuxuryRoom from "../../components/luxuryRoom"
import Aos from "aos"
import BookingOnline from "../../components/BookingOnline"
import BackToTop from "../../components/BackToTop"
import HeroBanner from "../../components/HeroBanner"
import { graphql } from "gatsby"
import Seo from "../../components/Seo"
import { PortableText } from "@portabletext/react"
const PetFriendly = ({ data }) => {
  React.useEffect(() => {
    Aos.init()
  }, [])
  const { rooms, title, heroImg, _rawDescription } =
    data?.sanityPages?.petFriendlyPage
  return (
    <>
      <Seo
        title={"Pet Friendly Page"}
        description={"Available room and activities for pet-friendly rooms."}
      />
      <Layout>
        <div className="header_bottom">
          <div className="container">
            <BookingOnline />
          </div>
          <BackToTop />
        </div>
        <HeroBanner
          bannerImg={heroImg?.asset?.gatsbyImageData}
          pageClass={"dine__drinks"}
        />
        <div className="environment__area pet">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="pet_blk">
                  <h3
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="1000"
                  >
                    {title}
                  </h3>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="1100"
                  >
                    <PortableText value={_rawDescription} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                {rooms.map((item, index) => (
                  <LuxuryRoom
                    key={index}
                    data={item}
                    boxClass={"luxury__item"}
                    oneClass={"pets"}
                    index={index + 1}
                    length={rooms.length}
                    btnNotrequired
                    gatsbyImage
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default PetFriendly

export const petFriendlyRoom = graphql`
  query ($id: String!) {
    sanityPages(id: { eq: $id }) {
      title
      template
      handle {
        current
      }
      petFriendlyPage {
        title
        _rawDescription
        heroImg {
          asset {
            gatsbyImageData(fit: FILLMAX, formats: WEBP)
          }
        }
        rooms {
          title
          thumbnail {
            asset {
              gatsbyImageData(fit: FILLMAX, formats: WEBP)
            }
          }
          description
        }
      }
    }
  }
`
